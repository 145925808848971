import React from "react";
import { Flex, Spinner } from "@chakra-ui/react";

const LoadingOverlay = ({ isLoading, bgColor, spinnerColor }) => {
    if (!isLoading) return null;

    return (
        <Flex
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bg={bgColor}
            zIndex="100"
            justifyContent="center"
            alignItems="center"
            borderRadius='16px'
        >
            <Spinner                             
                thickness='4px'
                speed='0.65s'
                emptyColor={bgColor}
                color={spinnerColor}
                size='xl' 
            />
        </Flex>
    );
};

export default LoadingOverlay;
