import React from 'react';
import { Text, Box, Icon, HStack, Divider, UnorderedList, ListItem, List,
    Table, Thead, Tbody, Tr, Th, Td
 } from "@chakra-ui/react";
import { FaRegLightbulb } from "react-icons/fa";

export const newTheme = {
    h1: props => {
        const { children } = props;
        return (
            <Text fontSize='5xl' fontWeight='bold' mb='5'>
                {children}
            </Text>
        );
    },

    h2: props => {
        const { children } = props;
        return (
            <Text fontSize='3xl' fontWeight='bold' mb='5' mt='10'>
                {children}
            </Text>
        );
    },

    h3: props => {
        const { children } = props;
        return (
            <Text fontSize='2xl' fontWeight='bold' mb='5' mt='10'>
                {children}
            </Text>
        );
    },

    p: props => {
        const { children } = props;
        return (
            <Text mb='2'>
                {children}
            </Text>
        );
    },
    
    hr: () => (
        <Divider my={4} />
    ),

    ol: props => {
        return (
            <List as="ol" spacing={2} pl={4} my={4}>
                {React.Children.map(props.children, child => {
                    if (React.isValidElement(child) && child.props.children) {
                        const content = child.props.children;
                        const match = typeof content === 'string' && content.match(/^(\d+)\.\s(.+)/);
                        if (match) {
                            return (
                                <ListItem display="flex" alignItems="flex-start">
                                    <Text mr={2}>{match[1]}.</Text>
                                    <Text flex={1}>{match[2]}</Text>
                                </ListItem>
                            );
                        }
                    }
                    return child;
                })}
            </List>
        );
    },

    ul: props => {
        return (
            <UnorderedList spacing={2} pl={4} my={4}>
                {props.children}
            </UnorderedList>
        );
    },

    li: props => {
        const { children } = props;
        let content = children;

        if (typeof children === 'string') {
            const match = children.match(/^(\d+)\.\s(.+)/);
            if (match) {
                return (
                    <ListItem display="flex" alignItems="flex-start">
                        <Text mr={2}>{match[1]}.</Text>
                        <Text flex={1}>{match[2]}</Text>
                    </ListItem>
                );
            }
        }

        return <ListItem>{content}</ListItem>;
    },

    blockquote: (props) => {
        return (
            <Box
                borderLeft="5px solid"
                borderColor="blue.500"
                pl={4}
                py={2}
                my={4}
                bg="#1E1E1E"
                borderRadius='10'
            >
                <HStack>
                    <Icon as={FaRegLightbulb} />
                    <Text mt='2'>{props.children}</Text>
                </HStack>
            </Box>
        );
    },

    // New table component to render Markdown tables
    table: props => {
        return (
            <Table variant="simple" my={4}>
                {props.children}
            </Table>
        );
    },

    thead: props => (
        <Thead>{props.children}</Thead>
    ),

    tbody: props => (
        <Tbody>{props.children}</Tbody>
    ),

    tr: props => (
        <Tr>{props.children}</Tr>
    ),

    th: props => (
        <Th>{props.children}</Th>
    ),

    td: props => (
        <Td>{props.children}</Td>
    ),
};


export const documentSummaryMDTheme = {
    h1: () => <></>, // h1 비워두기

    h2: (props) => (
        <Text fontSize="3xl" fontWeight="bold" mb="6" mt="8">
            {props.children}
        </Text>
    ),

    h3: (props) => (
        <Text fontSize="2xl" fontWeight="bold" mb="5" mt="8">
            {props.children}
        </Text>
    ),

    p: (props) => <Text mb="2">{props.children}</Text>,

    hr: () => <Divider my={4} />,

    ol: (props) => (
        <List as="ol" spacing={2} pl={4} my={4}>
            {props.children}
        </List>
    ),

    ul: (props) => (
        <UnorderedList spacing={2} pl={4} my={4}>
            {props.children}
        </UnorderedList>
    ),

    li: (props) => <ListItem>{props.children}</ListItem>,

    blockquote: (props) => (
        <Box
            borderLeft="5px solid"
            borderColor="blue.500"
            pl={4}
            py={2}
            my={4}
            bg="#1E1E1E"
            borderRadius="10"
        >
            <HStack spacing={3}>
                <Icon as={FaRegLightbulb} color="blue.300" />
                <Text mt="2">{props.children}</Text>
            </HStack>
        </Box>
    ),

    table: (props) => (
        <Box overflowX="auto" my={10}>
            <Table variant="striped" colorScheme="gray" my={2} minWidth="600px">
                {props.children}
            </Table>
        </Box>
    ),

    thead: (props) => (
        <Thead>
            <Tr>{props.children}</Tr>
        </Thead>
    ),

    tbody: (props) => <Tbody>{props.children}</Tbody>,

    tr: (props) => <Tr>{props.children}</Tr>,

    th: (props) => (
        <Th textAlign="left" px={4} py={2}>
            {props.children}
        </Th>
    ),
    td: (props) => (
        <Td textAlign="left" px={4} py={2} minWidth="150px">
            {props.children}
        </Td>
    ),
};

