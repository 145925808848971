import React, { useCallback } from 'react';
import { Button, IconButton, HStack, useColorModeValue } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';

const Pagination = ({ selectedPage, setSelectedPage, allRowsNumber, rowsNumberPerPage }) => {
  const allNumberBoxes = Math.ceil(allRowsNumber / rowsNumberPerPage);
  const boxesBg = useColorModeValue('gray.200', 'gray.700');
  const activeColor = useColorModeValue('black', 'white');
  const inactiveColor = useColorModeValue('gray', 'gray');

  const getRowFirstPage = (pageNumber) => {
    return Math.floor((pageNumber - 1) / 10) * 10 + 1;
  };

  const getPreviousRowFirstPage = (pageNumber) => {
    const previousFirstPage = getRowFirstPage(pageNumber) - 10;
    return previousFirstPage > 0 ? previousFirstPage : 1;
  };

  const getNextRowFirstPage = (pageNumber) => {
    const nextFirstPage = getRowFirstPage(pageNumber) + 10;
    const maxFirstPage = getRowFirstPage(allNumberBoxes);
    return nextFirstPage <= maxFirstPage ? nextFirstPage : getRowFirstPage(pageNumber);
  };

  const returnTenBoxesForThisRow = useCallback(
    (thisNumber) => {
      const thisRowFirstNum = getRowFirstPage(thisNumber);

      let boxes = [];
      for (
        let i = thisRowFirstNum;
        i <= Math.min(thisRowFirstNum + 9, allNumberBoxes);
        i++
      ) {
        boxes.push(
          <Button
            key={i}
            bg={boxesBg}
            fontSize="20px"
            onClick={() => setSelectedPage(i)}
            color={selectedPage === i ? activeColor : inactiveColor}
            borderRadius="13px"
          >
            {i}
          </Button>
        );
      }
      return boxes;
    },
    [allNumberBoxes, boxesBg, activeColor, inactiveColor, selectedPage, setSelectedPage]
  );

  return (
    <>
      {allNumberBoxes > 1 && (
        <HStack>
          <IconButton
            icon={<ArrowLeftIcon />}
            onClick={() => {
              const previousFirstPage = getPreviousRowFirstPage(selectedPage);
              setSelectedPage(previousFirstPage);
            }}
            borderRadius="13px"
            bg="transparent"
          />
          {returnTenBoxesForThisRow(selectedPage)}
          <IconButton
            icon={<ArrowRightIcon />}
            onClick={() => {
              const nextFirstPage = getNextRowFirstPage(selectedPage);
              setSelectedPage(nextFirstPage);
            }}
            borderRadius="13px"
            bg="transparent"
          />
        </HStack>
      )}
    </>
  );
};

export default Pagination;
