import React, { useRef } from 'react';
import { getDomains, postChunks, postRun, getDownloadFile } from '../Api';
import { useState, useEffect, useCallback } from 'react';
 
import { Flex, Box, Spacer , Input, Container,  HStack, Skeleton, useBreakpointValue,
    Tabs, TabList, TabPanels, Tab, TabPanel, Stack, Card, CardBody, CardHeader,
    Heading, Text, useToast, IconButton, Menu, MenuButton, Image,
    Button, MenuList, MenuItem, Tag,
    VStack, Spinner,
    CardFooter,
    Divider,
} from '@chakra-ui/react';
 
import { RepeatIcon, ChevronDownIcon, SearchIcon } from '@chakra-ui/icons'
import { IoIosSend, IoMdDownload } from 'react-icons/io';
 
import LoadingOverlay from '../components/LoadingOverlayComponent';
import { usePageColor } from '../contexts/ColorContext';
 
import {newTheme} from '../components/mdstyle'
import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
import Markdown from "react-markdown";
 
const ChatbotPage = ({checkExpireTime}) => {
 
    const colors = usePageColor('chatbot');
 
    const toast = useToast();
    const inputRef = useRef(null);
 
    const [allDomainsData, setAllDomainsData] = useState({ result: [] });
    const [activedDomainIndex, setActivedDomainIndex] = useState(1);
 
    const [inputQuery, setInputQuery] = useState("");
    const [postRunTriggered, setPostRunTriggered] = useState(false);
 
    const [queryResponses, setQueryResponses] = useState('');
    const [chunkResponses, setChunkResponses] = useState({ results: [] });
    const [naverApiResponses, setNaverApiResponses] = useState([]);
    const [isHandlingEvent, setIsHandlingEvent] = useState(false);
    const [isFormLoading, setIsFormLoading] = useState(false);
 
    const [isQueryResponseShowed, setIsQueryResponseShowed] = useState(false);
    const [isChunkResponseShowed, setIsChunkResponseShowed] = useState(false);
    const [isAIResponseLoading, setIsAIResponseLoading] = useState(false);
    const [gptSummary, setGPTSummary] = useState([]);

    
    const togglePostRunWrapper = () => {
        if (!isHandlingEvent) {
            setIsHandlingEvent(true);
            handlePostRun();
            setTimeout(() => setIsHandlingEvent(false), 500);
        }
    };
 
    const handleGetGPTSummary = useCallback(async (gpt_summary) => {
        try {
            if (gpt_summary && typeof gpt_summary === 'string') {
                const regex = /<file_id: (\d+)>(.*?)<\/file_id>/gs;
                const parsedSummary = {};
                let match;
                
                while ((match = regex.exec(gpt_summary)) !== null) {
                    const fileId = match[1];
                    let content = match[2]?.trim(); // 파일 내용 추출 후 트림 처리
    
                    if (content) {
                        // 제목 제거 등의 추가 가공 로직
                        content = content.replace(/.*?## .*?(\n|$)/s, '').trim(); // 원하는 패턴 제거
    
                        parsedSummary[fileId] = content;
                    }
                }
    
                console.log("Parsed gpt_summary:", parsedSummary); // 변환된 객체 확인
    
                setGPTSummary(parsedSummary); // 객체 형태로 설정
            }
        } catch (error) {
            toast({
                title: 'Notice',
                description: '문서요약 실패',
                status: 'warning',
                isClosable: true,
                position: 'top'
            });
        }
    }, [toast]);

    useEffect(() => {
        (async () => {
            try {
                const data = await getDomains();
                if (data.result === "No Token Sent") {
                    setAllDomainsData({ result: [] });
                } else {
                    setAllDomainsData(data);
                    //setActivedDomainIndex(data.result[0]?.domain_id);
                    //setInputQuery(data.result.find(domain => domain.domain_id === activedDomainIndex)?.default_prompt_text || "데이터 로딩 중...")
                    //위 문장 주석처리 할 경우 inputQuery가 뜨지 않고 placeholder에 뜨도록 해둠.
                }                
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '도메인 정보 가져오기 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        })();
    }, [toast, activedDomainIndex]);
 
    const handleGetChunks = useCallback(async (fileChunks) => {
 
        await checkExpireTime();
 
        if (fileChunks.file_ids && fileChunks.chunk_ids) {
            try {
                const data = await postChunks(fileChunks);
                console.log("data", data);

                setChunkResponses(data?.results);
            } catch (error) {
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            }
        } else {
            return;
        }  
 
    }, [toast]);
   
 
    const handlePostRun = useCallback(async () => {
 
        await checkExpireTime();
   
        if (inputQuery === "") {
            toast({
                title: 'Failed',
                description: '질문을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        } else {
            setIsFormLoading(true);
           
            setQueryResponses(''); // 기존 AI답변 초기화
            setChunkResponses([]); // 기존 AI답변 청크 초기화
   
            setIsQueryResponseShowed(false);
            setIsChunkResponseShowed(false);
            setIsAIResponseLoading(true);

            try {
                const stream = postRun(inputQuery);

                for await (let token of stream) {
 
                    if (token.query_response) {
                       
                        setIsQueryResponseShowed(true);
                        setIsChunkResponseShowed(true);
                        setIsFormLoading(false);
                        setIsAIResponseLoading(false);
                       
                        setQueryResponses(prev => prev + token.query_response)
 
                    }
 
                    if (token.file_ids && token.chunk_ids) {
                        let fileChunks = {
                            file_ids: token.file_ids,
                            chunk_ids: token.chunk_ids
                        }
                        handleGetChunks(fileChunks);

                        //console.log("Setting gpt_summary:", token.gpt_summary); // 로그 추가
                        if (token.gpt_summary) {
                            handleGetGPTSummary(token.gpt_summary);
                        } else {
                            setGPTSummary([]); // gpt_summary가 없을 경우 빈 문자열로 설정
                        }
                       
                    }
                   
                    // [{title: '제목', link: '링크', pDate: '날짜'}, {}, {}]
 
                    if (token.title && token.link && token.pDate) {
                        let newResponses = [];
 
                        for (let i = 0; i < token.title.length; i++) {
                            let naverApiResponse = {
                                title: token.title[i],
                                link: token.link[i],
                                pDate: token.pDate[i]
                            };
                            newResponses.push(naverApiResponse);
                        }
 
                        setNaverApiResponses(newResponses);
                    }
 
 
                    if (token.last_answer === "Y") {
                        setQueryResponses(token.query_response);
                        break;
                    }
                }
                setPostRunTriggered(true);
   
            } catch {
                toast({
                    title: 'Failed',
                    description: '답변 처리 실패',
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
   
            } finally {
                setIsFormLoading(false);
                setIsQueryResponseShowed(true);
                setIsAIResponseLoading(false);
            }
        }
    }, [inputQuery, toast, handleGetChunks, setPostRunTriggered, handleGetGPTSummary])

    useEffect(()=>{
        if (postRunTriggered) {
            if (queryResponses.length < 5) {
                console.log("해당 키워드만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
                setQueryResponses("해당 키워드만으로는 답변을 드릴 수 없습니다. 문장을 다르게 작성하시거나 다른 키워드를 입력해주세요.")
            }
            setPostRunTriggered(false);
        }
       
    }, [postRunTriggered, queryResponses, setPostRunTriggered])
 
    useEffect(() => {
        if (chunkResponses && Object.keys(chunkResponses).length > 0) {
            // 5초 후에 setIsChunkResponseShowed를 true로 설정
            const timer = setTimeout(() => {
                setIsChunkResponseShowed(true);
            }, 5000);
    
            // 클린업 함수: 의존성 변경 시 타이머 정리
            return () => clearTimeout(timer);
        }
    }, [chunkResponses]);
 
    useEffect(()=>{
        setIsQueryResponseShowed(false);
        setIsChunkResponseShowed(false);
    }, [activedDomainIndex])
 
    const handleGetFileDownload = useCallback(async (fileId) => {
        try {
            await getDownloadFile(fileId)
        } catch {
            toast({
                title: 'Failed',
                description: '파일 다운로드 실패',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, []);

    const chunkResponsesArray = Array.isArray(chunkResponses) ? chunkResponses : Object.values(chunkResponses || []);

    // 파일 ID별로 chunkResponses를 그룹화하는 함수
    const groupChunkResponsesByFileId = (chunkResponsesArray) => {
        const groupedResponses = {};

        chunkResponsesArray.forEach((chunkResponse) => {
            const fileId = chunkResponse.file_ids;

            // fileId가 정의되어 있는지 확인
            if (!fileId) {
                // console.warn("Invalid chunk response: Missing file_id", chunkResponse);
                return; // fileId가 없는 항목은 건너뜀
            }

            // 그룹 초기화
            if (!groupedResponses[fileId]) {
                groupedResponses[fileId] = {
                    file_ids: fileId,
                    title: chunkResponse.title, // 첫번째 chunk의 title 사용
                    content: chunkResponse.Content || "", // Content가 없는 경우 빈 문자열로 초기화
                };
            } else {
                // 같은 file_id가 이미 있으면 내용을 추가
                const additionalContent = chunkResponse.Content || ""; // Content가 없는 경우 빈 문자열로 처리
                groupedResponses[fileId].content += `<br /><br /><hr /><br />${additionalContent}`;
            }
        });

        // 객체를 배열로 변환하여 반환
        return Object.values(groupedResponses);
    };

    
    const [loadingMessages] = useState([
        "질문과 관련된 내부 데이터를 검색 중입니다...",
        "관련 문서 요약 중입니다...",
        "답변 생성 중입니다..."
    ]);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    
    useEffect(() => {
        // isFormLoading이 true일 때 메시지를 업데이트하고, false가 되면 메시지 업데이트를 멈춤
        if (isFormLoading && currentMessageIndex < loadingMessages.length - 1) {
            const timeoutId = setTimeout(() => {
                setCurrentMessageIndex((prevIndex) => prevIndex + 1);
            }, 4000); // 3초마다 메시지 변경

            return () => clearTimeout(timeoutId); // 컴포넌트 언마운트 시 타이머 정리
        } else if (!isFormLoading) {
            // 로딩이 끝나면 메시지를 초기화
            setCurrentMessageIndex(0);
        }
    }, [isFormLoading, currentMessageIndex]);


    const groupedChunkResponses = groupChunkResponsesByFileId(chunkResponsesArray);

    const overflowTabFixed = useBreakpointValue({ base: false, md: true })


    return(
        <Container maxW='100%' minH='95vh' size='container.3xl'>
            <Container
                maxW = 'container.xl'
                bg={colors.ContainerMainBg}
                color={colors.ContainerPromptColor}
            >      
                    <Flex direction='column'>
                        <Container maxW='' position='relative' pb='10'>                            
                        {isFormLoading ? (
                            <Box align="center" alignItems="center" mt="70px">
                                <Spinner size="xl" />
                                <Text mt={4} fontSize="2xl" fontWeight="bold" className="loading-text">
                                    {loadingMessages[currentMessageIndex]}
                                </Text>
                            </Box>
                        ) : (
                            <>
                            {overflowTabFixed && (
                                <Tag mt='10' bg="#38B2AC" p="1">
                                        {allDomainsData.result.map((domainData) => (
                                            <Tag 
                                                bg="#38B2AC"
                                                key={domainData.domain_id}
                                                onClick={() => {
                                                    setActivedDomainIndex(domainData.domain_id);
                                                }}
                                                color="white"
                                                fontWeight="bold"
                                            >
                                                {domainData.domain_name}
                                            </Tag>
                                        ))}
                                </Tag>
                            )}
                            {!overflowTabFixed && (
                                <Flex mt='7'>
                                    <Menu>
                                        <MenuButton as={Button} rightIcon={<ChevronDownIcon />} bg={colors.AssistantMessageBg} w="300" borderRadius='13px'>
                                            {allDomainsData.result[activedDomainIndex - 1]?.domain_name || "데이터 로딩 중..."}
                                        </MenuButton>
                                        <MenuList>
                                            {allDomainsData.result.map((domainData) => (
                                                <MenuItem
                                                    key={domainData.domain_id}
                                                    onClick={() => {
                                                        setActivedDomainIndex(domainData.domain_id);
                                                    }}
                                                >
                                                    {domainData.domain_name}
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                    <Spacer />
                                </Flex>
                            )}
                            <Container maxW="1300px" boxShadow="md" borderRadius="md" p={4} border="1px solid #E2E8F0">
                                <Box align="center">
                                    <HStack>
                                        <Input
                                            placeholder={`어떤 질문을 하고 싶으신가요? ex) ${
                                                allDomainsData.result
                                                    ? allDomainsData.result.find(
                                                        (domain) => domain.domain_id === activedDomainIndex
                                                    )?.default_prompt_text || "데이터 로딩 중..."
                                                    : "데이터 로딩 중..."
                                            }`}
                                            borderRadius='13px'
                                            border="none"
                                            fontWeight="bold"
                                            focusBorderColor='transparent'
                                            fontSize="1.3rem" 
                                            flex="1"
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') togglePostRunWrapper();
                                            }}
                                            ref={inputRef}
                                            value={inputQuery}
                                            onChange={(e) => {
                                                setInputQuery(e.target.value);
                                            }}
                                        />
                                        <IconButton
                                            borderRadius='13px'
                                            onClick={() => {
                                                togglePostRunWrapper();
                                            }}
                                            icon={<SearchIcon fontSize="1.6rem" />}
                                            variant="ghost"
                                            color="#38B2AC"
                                            size="lg"
                                        />
                                        <IconButton
                                            icon={<RepeatIcon fontSize="1.6rem" />}
                                            onClick={() => {
                                                setIsQueryResponseShowed(false);
                                                setIsChunkResponseShowed(false);
                                                setInputQuery("");
                                                setActivedDomainIndex(1);
                                            }}
                                            borderRadius='13px'
                                            mr="2"
                                            size="lg"
                                            variant="ghost"
                                        />
                                    </HStack>
                                </Box>
                            </Container>
                            </>
                        )}
                    </Container>
                    <Spacer />         
                        <Container alignContent='center' maxW='' mt='10'>
                            <Box>
                                <Divider mb="10" border="1px solid #E2E8F0"/>
                                        {isAIResponseLoading && (
                                            <Stack pt='30px'>
                                                <Skeleton height='120px' />
                                                <Skeleton height='120px' />
                                                <Skeleton height='120px' />
                                            </Stack>
                                        )}  
                                        {isQueryResponseShowed && (
                                            <Markdown components={ChakraUIRenderer(newTheme)} skipHtml>
                                            {queryResponses}
                                            </Markdown>    
                                        )}                         
                            </Box>                          
                            <Stack spacing='4' mt='10'>
                                {isChunkResponseShowed && (
                                    <Stack spacing='4' mt='10'>
                                        {queryResponses.includes("관련 정보를 찾을 수 없습니다.") ? (
                                            <Box position="relative" mb="6">
                                                <Card
                                                    size="lg"
                                                    boxShadow="md"
                                                    border="1px solid #E2E8F0"
                                                    borderRadius="13px"
                                                    backgroundColor="white"
                                                >
                                                    <CardHeader>
                                                        <Heading size="md">관련된 자료를 찾을 수 없습니다.</Heading>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Text>
                                                            질문과 관련된 자료를 찾을 수 없습니다. 다른 질문으로 다시 시도해 주세요.
                                                        </Text>
                                                    </CardBody>
                                                </Card>
                                            </Box>
                                        ) : (
                                            // 관련 자료가 있을 때 일반 카드들을 렌더링
                                            groupedChunkResponses.map((chunkResponse) => (
                                                <Card
                                                    key={`${chunkResponse.file_ids}-${chunkResponse.chunk_ids}`}
                                                    size='lg'
                                                    boxShadow="lg"
                                                    borderRadius="13px"
                                                    backgroundColor="white"
                                                    mb="6"
                                                >
                                                    <CardHeader>
                                                        <Flex>
                                                            <Heading size='md'>
                                                                {chunkResponse.title}
                                                            </Heading>
                                                            <Spacer />
                                                            <IconButton
                                                                icon={<IoMdDownload />}
                                                                onClick={() => handleGetFileDownload(chunkResponse.file_ids)}
                                                            />
                                                        </Flex>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <VStack alignItems="flex-start" width="100%">
                                                            <Box
                                                                bg="gray.200"
                                                                borderRadius="20px"
                                                                padding="2"
                                                                width="120px"
                                                                mb="3"
                                                            >
                                                                <Text fontSize="lg" align="center" fontWeight="bold">검색 내용</Text>
                                                            </Box>
                                                            <Text mb="25px" dangerouslySetInnerHTML={{ __html: chunkResponse.content }} />
                                                            {gptSummary[chunkResponse.file_ids] && (
                                                                    <>
                                                                        <Box
                                                                            bg="gray.200"
                                                                            borderRadius="20px"
                                                                            padding="2"
                                                                            width="120px"
                                                                            mb="3"
                                                                        >
                                                                            <Text fontSize="lg" align="center" fontWeight="bold">원문 요약</Text>
                                                                        </Box>

                                                                        <Box>
                                                                            <Markdown components={ChakraUIRenderer()} skipHtml={false}>
                                                                                {gptSummary[chunkResponse.file_ids]}
                                                                            </Markdown>
                                                                        </Box>
                                                                    </>
                                                                )}
                                                        </VStack>
                                                    </CardBody>
                                                </Card>
                                            ))
                                        )}
                                    </Stack>
                                )}
                                <Box height="40px" />
                            </Stack>
                            {isChunkResponseShowed && naverApiResponses.length > 0 && (
                                <Stack spacing='4' mt='10'>
                                {naverApiResponses.map((naverApiResponse) => (
                                    <Card
                                    key={`${naverApiResponse.title}-${naverApiResponse.link}`}
                                    size='lg'
                                    >
                                    <CardHeader>
                                        <Heading size='md'>{naverApiResponse.title}</Heading>
                                    </CardHeader>
                                    <CardBody>
                                        <Text>
                                        {naverApiResponse.link}
                                        </Text>
                                        <Text>
                                        {naverApiResponse.pDate}
                                        </Text>
                                    </CardBody>
                                </Card>                            
                                ))}
                                </Stack>
                            )}                                                
                        </Container>
                        <Spacer/>
                    </Flex>                            
            </Container>                                        
        </Container>
    )
}
export default ChatbotPage;