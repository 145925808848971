import { 
    Container, Box, IconButton, HStack, Spacer, Text, useColorMode, useColorModeValue,   
    Menu, MenuButton, MenuList, MenuItem, useDisclosure, Button,
    MenuDivider, useBreakpointValue,
} from "@chakra-ui/react";
import { SunIcon, MoonIcon, HamburgerIcon, SettingsIcon, CloseIcon } from '@chakra-ui/icons';
import { useState, useEffect } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom"; // Import NavLink and useNavigate from react-router-dom
import { MdEnergySavingsLeaf, MdSpaceDashboard } from "react-icons/md";
import { IoDocument } from "react-icons/io5";
import { FaRobot, FaPlayCircle, FaSignOutAlt, FaSignInAlt } from "react-icons/fa";
import { FcNews, FcSearch, FcUpload, FcGlobe  } from "react-icons/fc";
import { useGlobal } from "../contexts/GlobalContext";
import { useLocation } from 'react-router-dom'; // useLocation 추가

const NavigationBar = ({checkExpireTime, domainMenu, shouldMenuRender, setShouldMenuRender}) => {
    const navigate = useNavigate();
    const location = useLocation()
    const [tokenAndSessionInfo, setTokenAndSessionInfo] = useState({});
    
    const { isSignedIn, setIsSignedIn } = useGlobal();

    const { isOpen: isControlCenterOpen, onOpen: onControlCenterOpen, onClose: onControlCenterClose } = useDisclosure();


    const navigationBarBg = useColorModeValue('#26273C', '#333333');
    const navigationBarColor = useColorModeValue('black', 'white');
    const controlCenterBg = useColorModeValue('#333333', '#e9e9e9');
    const controlCenterColor = useColorModeValue('white', 'black');

    const [currentPage, setCurrentPage] = useState(window.location.pathname);

    useEffect(() => {
        if (!isSignedIn && currentPage === '/rag') {
            // 로그인하지 않았을 때는 현재 위치를 저장하고 로그인 페이지로 이동
            navigate('/signin', { state: { from: location } });
        } else if (isSignedIn && currentPage === '/signin') {
            // 로그인 후 리다이렉트할 경로가 저장되어 있으면 그곳으로 이동, 없으면 기본 경로(/)
            const redirectPath = location.state?.from?.pathname || '/';
            navigate(redirectPath);
        }
    }, [isSignedIn, currentPage, navigate, location]);

    const handleSignOut = () => {
        localStorage.removeItem('access-token');
        localStorage.removeItem('refresh-token');
        localStorage.removeItem('session-exp');
        localStorage.removeItem('access-token-exp');
        localStorage.removeItem('refresh-token-exp');
        setIsSignedIn(false);
        navigate('/signin');
    };

    const check = async () => {
        const result = await checkExpireTime();
        if (result === false) {
            navigate('/signin')
        }
        setTokenAndSessionInfo(result);
    };

    const handleClick = () => {
        navigate('/'); // 홈 화면으로 이동
        window.location.reload(); // Reload the page after navigation
    };


    useEffect(() => {
        const handleLocationChange = () => {
            setCurrentPage(window.location.pathname);
        };
    
        window.addEventListener('popstate', handleLocationChange);
    
        return () => {
            window.removeEventListener('popstate', handleLocationChange);
        };
    }, []);

    const disappearComponent = useBreakpointValue({ base: true, lg: false });
    

    const handleMenuRender = (renderMenu) => {        
        if (domainMenu) {
            console.log(domainMenu)
            // Navbar 처리
            if (renderMenu === 'navbar') {
                let navBarMenu = [];
                
                // 로그아웃 상태에서 navbar에 ChatPage와 PlaygroundPage 표시
                // if (!isSignedIn) {
                //     Object.entries(domainMenu?.menu).forEach(([key, value]) => {  // index 대신 key 사용
                //         if (key === 'ChatPage' && value === true) {
                //             navBarMenu.push(
                //                 <Button as={Link} to="/" leftIcon={<FcSearch />} bg='transparent' key={key} color='white'>
                //                     {key}
                //                 </Button>
                //             );
                //         } else if (key === 'PlaygroundPage' && value) {
                //             navBarMenu.push(
                //                 <Button as={Link} to="/" leftIcon={<FcGlobe />} bg='transparent' key={key} color='white'>
                //                     {key}
                //                 </Button>
                //             );
                //         }
                //     });
                // }
    
                // 로그인 상태에서 RagPage와 ChatPage, PlaygroundPage 표시
                if (isSignedIn) {
                    Object.entries(domainMenu?.menu).forEach(([key, value]) => {  // index 대신 key 사용
                        if (key === 'RagPage' && value === true) {
                            navBarMenu.push(
                                <Button as={Link} to="/rag" leftIcon={<FcUpload />} bg='transparent' key={key} color='white'>
                                    {key}
                                </Button>
                            );

                        // if (key === 'ChatPage' && value === true) {
                        //     navBarMenu.push(
                        //         <Button as={Link} to="/" leftIcon={<FcSearch />} bg='transparent' key={key} color='white'>
                        //             {key}
                        //         </Button>
                        //     );
                        // } else if (key === 'RagPage' && value === true) {
                        //     navBarMenu.push(
                        //         <Button as={Link} to="/rag" leftIcon={<FcUpload />} bg='transparent' key={key} color='white'>
                        //             {key}
                        //         </Button>
                        //     );
                        // } else if (key === 'PlaygroundPage' && value) {
                        //     navBarMenu.push(
                        //         <Button as={Link} to="/" leftIcon={<FcGlobe />} bg='transparent' key={key} color='white'>
                        //             {key}
                        //         </Button>
                        //     );
                        // }
                    }});
                }
                
                return navBarMenu;
            }
    
            // Hamburger Menu 처리
            // if (renderMenu === 'hamburger') {
            //     let hamburgerMenu = [];
            //     Object.entries(domainMenu?.menu).forEach(([key, value]) => {  // index 대신 key 사용
            //         if (key === 'ChatPage' && value === true) {
            //             hamburgerMenu.push(
            //                 <MenuItem as={NavLink} to="/" key={key} icon={<FcSearch /> }>
            //                     Chat
            //                 </MenuItem>
            //             );
            //         } else if (key === 'PlaygroundPage' && value) {
            //             hamburgerMenu.push(
            //                 <MenuItem as={NavLink} to="/" key={key} icon={<FcGlobe />}>
            //                     Playground
            //                 </MenuItem>
            //             );
            //         } else if (key === 'SignInPage' && value === true && !isSignedIn) { // 로그아웃 상태에서만 SignInPage 렌더링
            //             hamburgerMenu.push(
            //                 <MenuItem as={NavLink} to="/signin" key={key} icon={<FaSignInAlt />}>
            //                     Sign In
            //                 </MenuItem>
            //             );
            //         } else if (key === 'RagPage' && value === true && isSignedIn) { // 로그인 상태에서만 RagPage 렌더링
            //             hamburgerMenu.push(
            //                 <MenuItem as={NavLink} to="/rag" key={key} icon={<FcUpload />}>
            //                     RAG Management
            //                 </MenuItem>
            //             );
            //         }
            //     });
            //     return hamburgerMenu;
            // }
        // } else {
        //     return (
        //         // <Button as={NavLink} to="/" icon={<FaRobot />}>
        //         //     로딩중
        //         // </Button>
        //     );
        }
    };
    
    
    
    
    
    
    

    return (
        <Container
            maxW='100%'
            size="container.3xl"
            h='6vh'
            bg={navigationBarBg}
            color={navigationBarColor}
        >
            <HStack alignItems="center" h="100%">
                <HStack spacing={40}>
                <img 
                    src='/ci_didim365_en_w2.png' // Assuming the image is located in the public folder
                    alt='Didim365 Logo'
                    style={{ width: '170px', height: 'auto', cursor: 'pointer' }} 
                    onClick={handleClick}
                />
                {!disappearComponent && (
                    <>
                    {!isSignedIn && (
                    <HStack spacing={10}>
                        {handleMenuRender('navbar')}            
                    </HStack>              
                    )}
                    
                    {isSignedIn && (
                    <HStack spacing={10}>
                        {handleMenuRender('navbar')}                    
                    </HStack>              
                    )}
                    </>
                )}                                
                </HStack>                
                <Spacer />
                <Box>
                {domainMenu?.menu?.SignInPage && (
                    <IconButton 
                        icon={isSignedIn? <FaSignOutAlt color='white'/>:<FaSignInAlt color='white'/> }
                        bg='transparent'
                        onClick={isSignedIn? ()=>{handleSignOut()} : ()=>{navigate('/signin')}}
                    />
                )}
                    {isSignedIn && (
                    <IconButton 
                        icon={<SettingsIcon color='white'/>} 
                        onClick={()=>{
                            check()
                            if (!isControlCenterOpen) {
                                onControlCenterOpen();
                            } else {
                                onControlCenterClose();
                            }
                        }} 
                        aria-label="Settings"
                        bg='transparent'
                        color='white'
                    />
                    )}                    
                    {/* <Menu>
                        <MenuButton
                            as={IconButton}
                            aria-label='Options'
                            icon={<HamburgerIcon color='white'/>}
                            variant='outline'
                            borderWidth='0px'
                            bg='transparent'
                        />
                        <MenuList>
                            {!isSignedIn && (
                                <>            
                                    {handleMenuRender('hamburger')}
                                </>                                
                            )}                   
                            {isSignedIn && (
                                <>
                                    {handleMenuRender('hamburger')}
                                </>
                            )}
                        </MenuList>
                    </Menu> */}
                </Box>
            </HStack>
            {isControlCenterOpen && (
                <Box
                    position="fixed"
                    top="16"
                    right="4"
                    width="300px"
                    padding="4"
                    backgroundColor={controlCenterBg}
                    color={controlCenterColor}
                    borderRadius="13px"
                    boxShadow="lg"
                    backdropFilter="blur(10px)"
                    zIndex="10"
                    opacity="1"
                >
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Text fontWeight="bold">Control Center</Text>
                        <IconButton 
                            icon={<CloseIcon />} 
                            size="sm" 
                            onClick={onControlCenterClose} 
                            aria-label="Close"
                            backgroundColor="transparent"
                            _hover={{ backgroundColor: "whiteAlpha.300" }}
                        />
                    </Box>
                    <Text mt="2">
                    세션 만료시간
                    <br />
                    {tokenAndSessionInfo.sessionExpireTime? tokenAndSessionInfo.sessionExpireTime: '세션 만료시간을 확인할 수 없습니다.'}
                    </Text>
                    <Text mt="2">
                    엑세스 토큰 만료시간
                    <br />
                    {tokenAndSessionInfo.accessTokenExpireTime? tokenAndSessionInfo.accessTokenExpireTime: '엑세스 토큰 만료시간을 확인할 수 없습니다.'}
                    </Text>
                    <Text mt="2">
                    리프레시 토큰 만료시간
                    <br /> 
                    {tokenAndSessionInfo.refreshTokenExpireTime? tokenAndSessionInfo.refreshTokenExpireTime: '리프레시 토큰 만료시간을 확인할 수 없습니다.'}
                    </Text>
                </Box>
            )}
            
        </Container>
        
    );
};

export default NavigationBar;
