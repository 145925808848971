import {
    Container, ButtonGroup, Tabs, TabList, TabPanels, Tab, TabPanel,
    Divider, Textarea, HStack, Flex, Spacer, IconButton, Input, Button,
    Box, Text, VStack, useToast, Switch, Card, CardHeader, CardBody, Center, Image, Skeleton,
    Icon, Heading
} from "@chakra-ui/react";
import Cookies from 'js-cookie';
import { IoMdDownload, IoIosSend, IoMdSend } from 'react-icons/io';
import { useState, useRef, useCallback, useEffect } from 'react';
import { FaPaperclip, FaPlus, FaPenToSquare, FaRegTrashCan } from "react-icons/fa6";
import { FaRegThumbsUp, FaRegThumbsDown, FaThumbsDown, FaThumbsUp } from "react-icons/fa";
import { DeleteIcon, ArrowBackIcon, RepeatIcon } from '@chakra-ui/icons';

import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react'


import ChakraUIRenderer from 'chakra-ui-markdown-renderer';
// import Markdown from "react-markdown";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { documentSummaryMDTheme } from '../components/mdstyle';

import LoadingOverlay from "../components/LoadingOverlayComponent";
import {
    postMultitaskChat, getMultitaskChatHistory,
    getMultitaskThreadHistory, postMultitaskSummaryDocuments,
    postMultitaskDownloadReport, putMultitaskChatFeedback
} from "../Api";
import { usePageColor } from "../contexts/ColorContext";
import { scrollToBottom } from "../components/animation";

import { IoTimerOutline } from "react-icons/io5";

const PlaygroundPage = ({ domainMenu }) => {

    const colors = usePageColor('summary');

    const [currentNodeIndex, setCurrentNodeIndex] = useState(domainMenu.menu.PlaygroundPage?.DocumentSummary === true ? 0 : 1);

    // Tab-specific state variables

    const [documentSummaryInput, setDocumentSummaryInput] = useState('');
    const [documentSummaryFile, setDocumentSummaryFile] = useState([]);
    const [documentSummaryResponse, setDocumentSummaryResponse] = useState({});

    const [chatMessages, setChatMessages] = useState([]);
    const [chatInput, setChatInput] = useState('');

    const [thisFileDeleteIcons, setThisFileDeleteIcons] = useState([]);
    const [deletingFileIndices, setDeletingFileIndices] = useState([]);

    const [isFileInputAppearing, setIsFileInputAppearing] = useState(false);
    const [isDocumentSummaryTitleFixed, setIsDocumentSummaryTitleFixed] = useState(false);

    const [isQueryLoading, setIsQueryLoading] = useState(false);
    const [isButtonsDisappeared, setIsButtonsDisappeared] = useState(false);

    const [isChatHistoryFormAppearing, setIsChatHistoryFormAppearing] = useState(false);
    const [isNode0CurrentResponseBeing, setNode0CurrentResponseBeing] = useState(false);

    const [chatHistory, setChatHistory] = useState({ result: [] });
    const [thisChatThreadId, setThisChatThreadId] = useState('');

    const messagesEndRef = useRef(null);
    const fileInputRefs = useRef(null);
    const [isSending, setIsSending] = useState(false);

    const toast = useToast();
    const [documentGuideQuery, setDocumentGuideQuery] = useState("");
    const [loadingMessages, setLoadingMessages] = useState({});

    const [chatEnd, setChatEnd] = useState(true);


    const isThisFunctionUsable = (menu) => {

        // 0은 모두 안됨
        // 1은 DocumentSummary만
        // 2는 ChatMessages만
        // 3은 둘 다

        if (menu.DocumentSummary === false && menu.ChatMessages === false) return 0;
        else if (menu.DocumentSummary === true && menu.ChatMessages === false) return 1;
        else if (menu.DocumentSummary === false && menu.ChatMessages === true) return 2;
        else if (menu.DocumentSummary === true && menu.ChatMessages === true) return 3;
        else return 0;
    }

    useEffect(() => {
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable === 2) {
            setIsChatHistoryFormAppearing(true);
        } else {
            setIsChatHistoryFormAppearing(false);
        }
    }, [currentNodeIndex]);


    // 0. DocumentSummary

    const newDocumentSummary = useCallback(() => {
        setDocumentSummaryInput('');
        setDocumentGuideQuery('');
        setDocumentSummaryFile([]);
        setDocumentSummaryResponse({});
        setNode0CurrentResponseBeing(false);
        setDocumentGuideQuery('')
        fileInputRefs.current.value = null;

    }, [fileInputRefs]);

    const handleFileChange = useCallback((e) => {
        const files = Array.from(e.target.files);
        const existingFileNames = documentSummaryFile.map(file => file.fileName);

        // 파일 수 체크: 기존 파일 수와 새로 추가할 파일 수 합산
        if (documentSummaryFile.length + files.length > 5) {
            toast({
                title: 'Failed',
                description: '파일은 최대 5개까지 올릴 수 있습니다.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            e.target.value = '';
            return;
        }

        let newFiles = [];

        // 파일 이름 중복 체크 및 추가
        files.forEach((file) => {
            if (existingFileNames.includes(file.name)) {
                toast({
                    title: 'Failed',
                    description: `${file.name}은 이미 추가된 파일입니다.`,
                    status: 'error',
                    isClosable: true,
                    position: 'top'
                });
            } else {
                newFiles.push({
                    fileName: file.name,
                    file: file,
                    id: Date.now() + Math.random(), // 고유 ID 생성
                });
            }
        });

        // DocumentSummary의 경우 파일 데이터 저장
        if (currentNodeIndex === 0 && newFiles.length > 0) {
            setDocumentSummaryFile(prevFiles => [...prevFiles, ...newFiles]); // 기존 파일 목록에 추가
        }

        setIsFileInputAppearing(true);
        e.target.value = ''; // 입력값 초기화
    }, [documentSummaryFile, currentNodeIndex, toast]);


    const handleDeleteButtonClick = useCallback((index) => {
        setDeletingFileIndices(prev => [...prev, index]);
        // setIsFileInputDeleting(true);
        setTimeout(() => {
            if (currentNodeIndex === 0) {
                setDocumentSummaryFile(prev => prev.filter((_, i) => i !== index));
            }
            setDeletingFileIndices(prev => prev.filter(i => i !== index));
        }, 500);
    }, [currentNodeIndex, fileInputRefs]);


    const handleDocumentSummary = useCallback(async (userPrompt, documentGuideQuery, userFile) => {
        // 0 -> 둘다안됨
        // 1 -> DocumentSummary만
        // 2 -> ChatMessages만
        // 3 -> 둘다됨
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable !== 3 && usable !== 1) return;

        if (userPrompt === '' && !isDocumentSummaryTitleFixed) {
            toast({
                title: 'Failed',
                description: '제목은 필수입니다. 제목을 입력해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }

        if (
            !userFile ||
            !Array.isArray(userFile) ||
            userFile.length === 0 ||
            userFile.some(fileItem => !fileItem.file) // Check if any file is null or empty
        ) {
            toast({
                title: 'Failed',
                description: '파일이 없거나, 필드를 다 작성하지 않았습니다. 확인해주세요.',
                status: 'error',
                isClosable: true,
            });
            setNode0CurrentResponseBeing(false);
            return;
        }

        try {
            setIsQueryLoading(true);
            setIsButtonsDisappeared(true);
            setNode0CurrentResponseBeing(true);
            setDocumentSummaryResponse({});
            toast({
                title: 'Processing',
                description: '해당 작업을 처리하는데 오랜 시간이 소요될 수 있습니다. 페이지를 이탈하면 결과가 손실될 수 있습니다.',
                status: 'loading',
                isClosable: true,
                position: 'top-right',
                duration: 2000,
            });

            await new Promise(resolve => setTimeout(resolve, 2000));

            const data = await postMultitaskSummaryDocuments(userPrompt, documentGuideQuery, userFile, isDocumentSummaryTitleFixed);

            if (data?.error) {
                toast({
                    title: 'Failed',
                    description: data.error,
                    status: 'error',
                    isClosable: true,
                });
                setNode0CurrentResponseBeing(false);
                return;
            }

            setDocumentSummaryResponse(data);
            return;
        } catch (error) {
            toast({
                title: 'Failed',
                description: error.message ? error.message : '오류가 일어났습니다. 시스템 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            setNode0CurrentResponseBeing(false);
            return;
        } finally {
            setIsQueryLoading(false);
            setIsButtonsDisappeared(false);
        }
    }, [currentNodeIndex, isDocumentSummaryTitleFixed, isNode0CurrentResponseBeing])


    const handlePostMultitaskDownloadReport = useCallback(async () => {

        // 0 -> 둘다안됨
        // 1 -> DocumentSummary만
        // 2 -> ChatMessages만
        // 3 -> 둘다됨
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable !== 3 && usable !== 1) return;

        if (!documentSummaryResponse.report_title || !documentSummaryResponse.markdown_content) {
            toast({
                title: 'Failed',
                description: '가 생성중이니 잠시만 기다려주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
            return;
        }

        try {
            const reportTitle = documentSummaryResponse.report_title;
            const markdownContent = documentSummaryResponse.markdown_content;

            await postMultitaskDownloadReport(reportTitle, markdownContent);
        } catch (error) {
            toast({
                title: 'Failed',
                description: error.message ? error.message : '오류가 일어났습니다. 시스템 관리자에게 문의하세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }, [documentSummaryResponse])

    // 1. Chat

    const newChat = useCallback(() => {
        setThisChatThreadId('');
        setChatMessages([]);
        Cookies.remove('chat_thread');
    }, []);

    const handleChatSend = useCallback(async () => {

        // 0 -> 둘다안됨
        // 1 -> DocumentSummary만
        // 2 -> ChatMessages만
        // 3 -> 둘다됨
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable !== 3 && usable !== 2) return;

        if (chatInput.trim()) {
            const newMessage = { type: 'user', content: chatInput };
            setChatMessages(prev => [...prev, newMessage]);

            setChatEnd(false);

            setIsQueryLoading(true);  // 로딩 상태 시작
            const loadingMessageId = `loading-${Date.now()}`;
            setLoadingMessages(prev => ({ ...prev, [loadingMessageId]: true }));

            setChatInput('');

            const assistantResponse = "답변 생성 중입니다. 잠시만 기다려주세요...";
            const newAssistantMessage = { type: 'assistant', content: assistantResponse };

            setChatMessages(prev => [...prev, newAssistantMessage]);
            setLoadingMessages(prev => ({ ...prev, [loadingMessageId]: false }));
            setIsQueryLoading(false);
        }

        const stream = postMultitaskChat(chatInput, thisChatThreadId);
        let assistantResponse = '';

        const updateChatMessages = (newContent, chatUUID) => {
            setChatMessages(prev => {
                // setChatEnd(false);
                const newMessages = [...prev];
                if (newMessages.length > 0 && newMessages[newMessages.length - 1].type === 'assistant') {
                    newMessages[newMessages.length - 1].content = newContent;
                    newMessages[newMessages.length - 1].chat_uuid = chatUUID;
                } else {
                    newMessages.push({
                        type: 'assistant',
                        content: newContent,
                        chat_uuid: chatUUID
                    });
                }
                return newMessages;
            });
        };

        for await (let token of stream) {
            if (token.query_response && token.chat_uuid && token.last_answer == "N") {
                assistantResponse += token.query_response;
                let chatUUID = token.chat_uuid;
                updateChatMessages(assistantResponse, chatUUID);
            }
            if (token.chat_thread) {
                if (thisChatThreadId === '') {
                    setThisChatThreadId(token.chat_thread);
                }
            }
            if (token.last_answer == "Y") {
                // activate Feedback button 
                setChatEnd(true);
            }

        }

    }, [chatInput, thisChatThreadId]);


    useEffect(() => {
        console.log("chatEnd updated:", chatEnd);
        handleGetMultitaskChatHistory();
    }, [chatEnd]);

    useEffect(() => {
        (async () => {
            try {
                // 0 -> 둘다안됨
                // 1 -> DocumentSummary만
                // 2 -> ChatMessages만
                // 3 -> 둘다됨
                const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

                if (usable !== 3 && usable !== 2) return;

                const data = await getMultitaskChatHistory();
                if (data.result === "No Token Sent") {
                    setChatHistory({ result: [] });
                } else {
                    setChatHistory(data);
                }
            } catch (error) {
                setChatHistory({ result: [] });
                throw new Error(error);
            }
        })();
    }, []);

    useEffect(() => {
        if (isDocumentSummaryTitleFixed) {
            setDocumentSummaryInput('');
        }
    }, [isDocumentSummaryTitleFixed, documentSummaryInput]);


    const handleGetMultitaskChatHistory = useCallback(async () => {
        try {

            // 0 -> 둘다안됨
            // 1 -> DocumentSummary만
            // 2 -> ChatMessages만
            // 3 -> 둘다됨
            const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

            if (usable !== 3 && usable !== 2) return;

            const data = await getMultitaskChatHistory();
            if (data.result === "No Token Sent") {
                setChatHistory({ result: [] });
            } else {
                setChatHistory(data);
            }
        } catch (error) {
            setChatHistory({ result: [] });
            throw new Error(error);
        }
    }, []);


    const handleGetChatThread = useCallback(async (thread_id) => {

        // 0 -> 둘다안됨
        // 1 -> DocumentSummary만
        // 2 -> ChatMessages만
        // 3 -> 둘다됨
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable !== 3 && usable !== 2) return;

        const data = await getMultitaskThreadHistory(thread_id);
        try {
            if (data.result.length === 0) {
                setChatMessages([]);
            } else {
                let chatHistoryTemp = [];

                data.result.forEach((each) => {
                    chatHistoryTemp.push({ type: 'user', content: each.question });
                    chatHistoryTemp.push({ type: 'assistant', content: each.answer, chat_uuid: each.chat_uuid, feedback: each.feedback, feedbackValue: each.feedback_detail });
                });

                setChatMessages(chatHistoryTemp);
            }
        } catch (error) {
            setChatMessages([]);
            throw new Error(error);
        }

    }, [thisChatThreadId]);


    // update feedback button 
    const updateMessage = (index, newData) => {
        setChatMessages(prevMessages =>
            prevMessages.map((data, i) =>
                // 구조 분해 할당으로 마지막 값을 덮어 씌움
                i === index ? { ...data, ...newData } : data
            )
        );
    };


    const handlePutMultitaskChatFeedback = async (chatUUID, feedback, feedbackDetail) => {
        const feedbackDetailValue = feedbackDetail === undefined ? null : feedbackDetail;

        // 0 -> 둘다안됨    
        // 1 -> DocumentSummary만
        // 2 -> ChatMessages만
        // 3 -> 둘다됨
        const usable = isThisFunctionUsable(domainMenu.menu?.PlaygroundPage);

        if (usable !== 3 && usable !== 2) return;

        const data = await putMultitaskChatFeedback(chatUUID, feedback, feedbackDetailValue);
        if (data.result === 1) {
            toast({
                title: 'Success',
                description: '피드백이 성공적으로 전송되었습니다.',
                status: 'success',
                isClosable: true,
                position: 'top'
            });
        } else {
            toast({
                title: 'Failed',
                description: '피드백 전송에 실패했습니다. 다시 시도해주세요.',
                status: 'error',
                isClosable: true,
                position: 'top'
            });
        }
    }

    // Common
    const setInputValue = (value) => {
        switch (currentNodeIndex) {
            case 1: setChatInput(value); break;
            default: setDocumentSummaryInput(value); break;
        }
    };


    const getInputFileValue = () => {
        switch (currentNodeIndex) {
            case 1: return [{ fileName: '', file: null, id: Date.now() }];;
            default: return documentSummaryFile;
        }
    };


    const modalFileInputButtonClick = () => {
        const currentRef = fileInputRefs?.current;
        if (currentRef) {
            currentRef.click();
        } else {
            console.error(`No ref found for index ${currentNodeIndex}`);
        }
    };

    const handleSendButtonClick = useCallback(async () => {
        let userPrompt = '';
        let userFile = null;

        switch (currentNodeIndex) {
            case 1:
                await handleChatSend();
                return;
            default:
                userPrompt = isDocumentSummaryTitleFixed ? '' : documentSummaryInput;
                userFile = documentSummaryFile;
                await handleDocumentSummary(userPrompt, documentGuideQuery, userFile);
                return;
        }
    }, [isDocumentSummaryTitleFixed, currentNodeIndex, documentSummaryInput, documentGuideQuery, documentSummaryFile, toast, handleChatSend, handleDeleteButtonClick]);

    // Utils
    useEffect(() => {
        Cookies.remove('chat_thread');
    }, []);

    useEffect(() => {
        if (isFileInputAppearing) {
            const timer = setTimeout(() => {
                setIsFileInputAppearing(false);
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [isFileInputAppearing]);

    useEffect(() => {
        const fileValues = documentSummaryFile
        setThisFileDeleteIcons(new Array(fileValues.length).fill(false));
    }, [currentNodeIndex, documentSummaryFile]);

    useEffect(() => {
        scrollToBottom(messagesEndRef);

    }, [chatMessages, chatEnd])


    const allowNodeIndexTwoThisFileInputLeftIcon = (index) => {
        setThisFileDeleteIcons(prev => prev.map((icon, i) => i === index ? true : icon));
    };

    const resetFileDeleteIcons = (index) => {
        setThisFileDeleteIcons(prev => prev.map((icon, i) => i === index ? false : icon));
    };


    const renderPlacholderForDocumentSummary = () => {

        if (isDocumentSummaryTitleFixed) {
            return "자동 제목 생성이 될 예정입니다."
        } else {
            return "제목을 입력해주세요."
        }
    }

    let handleFeedbackChange = (e, index) => {
        let inputValue = e.target.value
        // setFeedbackValue(inputValue)
        updateMessage(index, { feedbackValue: inputValue })
    }


    return (
        <Container
            maxW="full"
            minH="90vh"
        >
            <Flex mt="2" justify="center" alignItems="start">
                {isChatHistoryFormAppearing && (
                    <Container
                        pl="0"
                        width="35vh"
                        minW="35vh"
                        minH='90vh'
                        maxHeight="60vh"
                        overflowY="auto"
                        borderRight="2px solid #C0D1D4"
                        height="100%"
                    >
                        <Flex>
                            <Box display="flex" flexDirection="row" justifyContent="space-between" borderRadius='12px' width="100%">
                                <Heading as='h4' size='sm' pt='5' pb='5' ml="3">
                                    채팅 기록
                                </Heading>

                                <Spacer />

                                <IconButton
                                    borderRadius='13px'
                                    borderColor="#c84557"
                                    borderWidth='2px'
                                    color="#c84557"
                                    size="sm"
                                    bg='white'
                                    _hover={{ bg: '#c84557', color: 'white' }}
                                    mr="3"
                                    mt="3"
                                    icon={<RepeatIcon />}
                                    onClick={() => {
                                        handleGetMultitaskChatHistory();
                                    }}
                                />
                                {currentNodeIndex === 1 && (
                                    <IconButton
                                        borderRadius='13px'
                                        icon={<FaPlus />}
                                        borderColor="#c84557"
                                        borderWidth='2px'
                                        color="#c84557"
                                        size="sm"
                                        bg='white'
                                        _hover={{ bg: '#c84557', color: 'white' }}
                                        mt="3"
                                        transition='all 0.5s ease'
                                        onClick={() => {
                                            newChat();
                                        }}
                                    />
                                )}
                            </Box>

                        </Flex>
                        <Divider />
                        {chatHistory.result.length === 0 && (
                            <Text textAlign="center" textColor="gray" fontSize="lg" mt="300">채팅 기록이 없습니다.</Text>
                        )}
                        {chatHistory.result.map((chat, index) => (
                            <Box
                                key={index}
                                p={2}
                                pt={3.5}
                                pb={3.5}
                                mt="0.1rem"
                                mb="0.1rem"
                                bg={chat.chat_thread === thisChatThreadId ? "gray.200" : ''}
                                borderRadius="md"
                                cursor="pointer"
                                fontSize="sm"
                                _hover={{
                                    bg: "gray.200",
                                    transition: "background-color 0.2s ease",
                                }}
                                onClick={() => {
                                    setThisChatThreadId(chat.chat_thread);
                                    handleGetChatThread(chat.chat_thread);
                                }}
                            // boxShadow="md"
                            >
                                <Text>{chat.question}</Text>

                            </Box>
                        ))}
                    </Container>
                )}

                <HStack
                    maxW='100%'
                    alignItems="stretch"
                    flex={isChatHistoryFormAppearing ? "1" : "0"}
                >
                    <Container
                        borderRadius={isChatHistoryFormAppearing ? "0" : "16px"}
                        p={isChatHistoryFormAppearing ? "1" : "3"}
                        minH='90vh'
                        paddingRight={isChatHistoryFormAppearing ? "" : "6"}
                        bg={colors.ContainerCenterBg}
                        // bg='red'
                        borderWidth="2px"
                        borderColor={isChatHistoryFormAppearing ? "transparent" : "#C0D1D4"}
                        position="relative"
                        mr={isChatHistoryFormAppearing ? "" : "20px"}
                        minW={isChatHistoryFormAppearing ? "container.lg" : 'container.md'}
                        maxW={isChatHistoryFormAppearing ? "container.lg" : 'container.md'}
                        overflow='auto'
                        alignContent="center"
                        mt={isChatHistoryFormAppearing ? "" : "2"}
                    >
                        <LoadingOverlay isLoading={isQueryLoading} bgColor={colors.ContainerCenterBg} />
                        <Flex ml="3" direction='column' height="95%">
                            {!isButtonsDisappeared && (
                                <Flex>
                                    <ButtonGroup spacing={3}>
                                        {domainMenu.menu.PlaygroundPage?.DocumentSummary === true ? (
                                            <Button
                                                borderRadius='20px'
                                                border='2px solid transparent'
                                                _selected={{ bg: '#c84557', borderColor: '#c84557', color: 'white' }}
                                                transition='all 0.3s ease'
                                                mr='3'
                                                onClick={() => {
                                                    setCurrentNodeIndex(0);
                                                }}
                                            >
                                                요약 문서
                                            </Button >
                                        ) : <></>}

                                    </ButtonGroup>
                                    <Spacer />

                                </Flex>
                            )}
                            <Divider
                                mt='4'
                                borderWidth='1px'
                                opacity={isQueryLoading ? 0 : 1}
                                transition='all 1s ease'
                                mb='3'
                            />
                            {currentNodeIndex === 0 && (
                                <Box
                                    ref={messagesEndRef}
                                    style={{
                                        animation: isQueryLoading ? 'fadeOutDown 1s ease' :
                                            !isQueryLoading && !isButtonsDisappeared ? 'fadeInUp 1s ease' : 'none'
                                    }}
                                >
                                    <VStack align="stretch" spacing={10} mb={4}>
                                        <Card borderRadius='16'>
                                            <CardHeader>
                                                <HStack>
                                                    <Switch
                                                        id='documentSummarySwitch'
                                                        onChange={() => {
                                                            setIsDocumentSummaryTitleFixed(prev => !prev)
                                                        }}
                                                    />
                                                    <Text>
                                                        {isDocumentSummaryTitleFixed ? "자동 제목 활성화!" : "활성화되면 HCX가 자동으로 제목을 생성해줍니다."}
                                                    </Text>
                                                </HStack>
                                            </CardHeader>
                                            <CardBody>
                                                <Input
                                                    onChange={(e) => {
                                                        setDocumentSummaryInput(e.target.value)
                                                    }}
                                                    borderRadius='13'
                                                    placeholder={renderPlacholderForDocumentSummary()}
                                                    value={documentSummaryInput}
                                                    readOnly={isDocumentSummaryTitleFixed ? true : false}
                                                    bg={isDocumentSummaryTitleFixed ? colors.InputBoxBg : colors.ContainerCenterBg}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            handleSendButtonClick();
                                                        }

                                                    }}
                                                    mb='10px'
                                                />
                                                <Input
                                                    placeholder='문서 작성 가이드를 입력해주세요.'
                                                    borderRadius='13px'
                                                    height='50px'
                                                    value={documentGuideQuery}
                                                    onChange={(e) => setDocumentGuideQuery(e.target.value)}
                                                    mb='10px'
                                                >
                                                </Input>
                                            </CardBody>
                                        </Card>
                                        <Card borderRadius='16'>
                                            <CardHeader>
                                                <HStack>
                                                    <Text>파일 업로드</Text>
                                                    <Spacer />
                                                    <IconButton
                                                        icon={<FaPlus />}
                                                        onClick={modalFileInputButtonClick}
                                                        borderRadius='13'
                                                    />
                                                </HStack>
                                            </CardHeader>
                                            <CardBody>
                                                {documentSummaryFile.length === 0 ? (
                                                    <Text align={"center"} color={"grey"}>파일이 없습니다. 파일을 추가해 주세요.</Text>
                                                ) : (
                                                    <VStack>
                                                        {documentSummaryFile.map((fileValue, index) => (
                                                            <Button
                                                                key={fileValue.id}
                                                                borderRadius='13px'
                                                                p='2'
                                                                minW='100px'
                                                                leftIcon={thisFileDeleteIcons[index] ? <DeleteIcon /> : <FaPaperclip />}
                                                                _hover={{
                                                                    bg: 'red.500',
                                                                    color: 'white',
                                                                    transform: 'translateX(-10px)'
                                                                }}
                                                                onMouseEnter={() => allowNodeIndexTwoThisFileInputLeftIcon(index)}
                                                                onMouseLeave={() => resetFileDeleteIcons(index)}
                                                                onClick={() => handleDeleteButtonClick(index)}
                                                                transition="all 0.5s ease"
                                                                opacity={deletingFileIndices.includes(index) ? 0 : 1}
                                                                style={{
                                                                    animation: isFileInputAppearing ? 'fadeInRight 0.5s ease' :
                                                                        deletingFileIndices.includes(index) ? 'fadeOutLeft 0.5s ease' : 'none'
                                                                }}
                                                                whiteSpace="nowrap"
                                                                overflow="hidden"
                                                                textOverflow="ellipsis"
                                                                display="block"
                                                                maxW='100%'
                                                            >
                                                                {`${fileValue.fileName}`}
                                                            </Button>
                                                        ))}
                                                    </VStack>
                                                )}
                                            </CardBody>
                                        </Card>
                                    </VStack>
                                    <Box>
                                        {!isNode0CurrentResponseBeing && (
                                            <Flex>
                                                <Spacer />
                                                <Button
                                                    borderRadius='12'
                                                    leftIcon={<IoMdSend />}
                                                    colorScheme='blue'
                                                    bg="#5B9BD5"
                                                    onClick={() => { handleSendButtonClick(); }}
                                                >
                                                    요약 문서 생성
                                                </Button>
                                            </Flex>
                                        )}
                                        {(isNode0CurrentResponseBeing && (
                                            <Flex>
                                                <Button
                                                    borderRadius='12'
                                                    leftIcon={<RepeatIcon />}
                                                    onClick={() => {
                                                        newDocumentSummary();
                                                    }}
                                                >
                                                    초기화
                                                </Button>
                                                <Spacer />
                                                <Button
                                                    borderRadius='12'
                                                    leftIcon={<IoMdSend />}
                                                    colorScheme='blue'
                                                    onClick={() => { handleSendButtonClick(); }}
                                                >
                                                    요약 문서 재생성
                                                </Button>
                                            </Flex>
                                        ))}
                                    </Box>
                                </Box>
                            )}
                            {currentNodeIndex === 1 && (
                                <Container
                                    borderRadius="16px"
                                    p='4'
                                    height="75vh"
                                    overflowY="auto"
                                    bg={colors.ContainerCenterBg}
                                    maxW="container.lg"
                                    fontSize="sm"
                                >
                                    <VStack align="stretch">

                                        {chatMessages.map((message, index) => (

                                            <Box
                                                key={index}
                                                maxW="70%"
                                                alignSelf={message.type === 'user' ? 'flex-end' : 'flex-start'} // You는 오른쪽, AI는 왼쪽
                                                textAlign={message.type === 'user' ? 'right' : 'left'} // You 텍스트 오른쪽, AI는 왼쪽 정렬
                                                position="relative"
                                            >
                                                {message.type !== 'user' &&
                                                    <Image
                                                        src={`${process.env.PUBLIC_URL}/ai_answer2.png`} // 적절한 경로로 변경
                                                        boxSize="60px" // 이미지 크기 설정
                                                        borderRadius="full" // 둥근 이미지
                                                        display="inline-block"
                                                    />
                                                }

                                                {/* 말풍선 */}
                                                <Box
                                                    bg={message.type === 'user' ? 'rgba(173, 216, 230, 0.8)' : 'gray.100'}
                                                    p={3.5}
                                                    borderRadius="lg"
                                                    boxShadow="md"
                                                >

                                                    <ReactMarkdown components={ChakraUIRenderer()}>
                                                        {message.content}
                                                    </ReactMarkdown>


                                                    {/* AI 메시지에 대한 피드백 버튼 */}
                                                    {message.type === 'assistant' && (
                                                        // Last message feedback button will be shown when stream end

                                                        (index != chatMessages.length - 1) ?
                                                            <HStack justify="flex-end" mt={2}>
                                                                <IconButton
                                                                    onClick={() => {
                                                                        handlePutMultitaskChatFeedback(message.chat_uuid, 'good', message.feedbackValue);
                                                                        updateMessage(index, { feedback: 'good' });
                                                                    }
                                                                    }
                                                                    icon={
                                                                        message.feedback === 'good'
                                                                            ? <FaThumbsUp color="#2B5D32" />
                                                                            : <FaRegThumbsUp color="#2B5D32" />
                                                                    }
                                                                    size="sm"
                                                                    bg="transparent"
                                                                />
                                                                <IconButton
                                                                    onClick={() => {
                                                                        handlePutMultitaskChatFeedback(message.chat_uuid, 'bad', message.feedbackValue)
                                                                        updateMessage(index, { feedback: 'bad' });
                                                                    }
                                                                    }
                                                                    icon={
                                                                        message.feedback === 'bad'
                                                                            ? <FaThumbsDown color="#c84557" />
                                                                            : <FaRegThumbsDown color="#c84557" />
                                                                    }
                                                                    size="sm"
                                                                    bg="transparent"
                                                                />
                                                            </HStack>
                                                            :
                                                            ((chatEnd) && (
                                                                <HStack justify="flex-end" mt={2}>
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            handlePutMultitaskChatFeedback(message.chat_uuid, 'good', message.feedbackValue);
                                                                            updateMessage(index, { feedback: 'good' });
                                                                        }
                                                                        }
                                                                        icon={
                                                                            message.feedback === 'good'
                                                                                ? <FaThumbsUp color="#2B5D32" />
                                                                                : <FaRegThumbsUp color="#2B5D32" />
                                                                        }
                                                                        size="sm"
                                                                        bg="transparent"
                                                                    />
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            handlePutMultitaskChatFeedback(message.chat_uuid, 'bad', message.feedbackValue)
                                                                            updateMessage(index, { feedback: 'bad' });
                                                                        }
                                                                        }
                                                                        icon={
                                                                            message.feedback === 'bad'
                                                                                ? <FaThumbsDown color="#c84557" />
                                                                                : <FaRegThumbsDown color="#c84557" />
                                                                        }
                                                                        size="sm"
                                                                        bg="transparent"
                                                                    />



                                                                </HStack>
                                                            )
                                                            )


                                                    )

                                                    }
                                                </Box>

                                                {
                                                    message.feedback === 'bad' &&
                                                    <Accordion allowMultiple marginTop="0.5rem" borderColor="white">
                                                        <AccordionItem>
                                                            <h2>
                                                                <AccordionButton>

                                                                    <Box as='span' flex='1' textAlign='left'>
                                                                        <HStack>
                                                                            <FaPenToSquare size="0.8rem" />
                                                                            <Text fontSize="sm">
                                                                                상세 피드백 전달하기
                                                                            </Text>
                                                                        </HStack>
                                                                    </Box>

                                                                    <AccordionIcon />
                                                                </AccordionButton>
                                                            </h2>
                                                            <AccordionPanel pb={4}>
                                                                <Textarea
                                                                    value={message.feedbackValue}
                                                                    onChange={(e) => handleFeedbackChange(e, index)}
                                                                    placeholder='피드백을 입력해주세요'
                                                                    size='sm'
                                                                />
                                                                <HStack>
                                                                    <Spacer />
                                                                    <Button size='sm' marginTop="0.7rem" variant='link'
                                                                        onClick={() => handlePutMultitaskChatFeedback(message.chat_uuid, message.feedback, message.feedbackValue)}
                                                                    >
                                                                        Send
                                                                    </Button>
                                                                </HStack>
                                                            </AccordionPanel>
                                                        </AccordionItem>

                                                    </Accordion>

                                                }

                                            </Box>
                                        ))



                                        }
                                        <div ref={messagesEndRef} />

                                    </VStack>
                                </Container>
                            )}

                            <Spacer />
                            {(!isButtonsDisappeared && currentNodeIndex !== 0) && (
                                <>
                                    <HStack
                                        alignItems="flex-end"
                                        mt='5'
                                        w="100%"
                                        style={{
                                            animation: isQueryLoading ? 'fadeOutDown 1s ease' :
                                                !isQueryLoading && !isButtonsDisappeared ? 'fadeInUp 1s ease' : 'none'
                                        }}
                                    >
                                        <Textarea
                                            placeholder="채팅 메시지를 입력하세요"
                                            borderRadius="13px"
                                            borderWidth="2px"
                                            mr={2}
                                            flex={1}
                                            minH="40px"
                                            maxH="200px"
                                            overflow="hidden"
                                            // readOnly={isDocumentSummaryTitleFixed? true: false}
                                            // bg={isDocumentSummaryTitleFixed? colors.InputBoxBg:colors.ContainerCenterBg}
                                            resize="none"
                                            value={currentNodeIndex === 0 ? documentSummaryInput : chatInput}
                                            onChange={(e) => setInputValue(e.target.value)}
                                            rows={1}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' && !e.shiftKey && !e.ctrlKey) {
                                                    e.preventDefault();
                                                    if (!isSending) {
                                                        setIsSending(true);
                                                        setTimeout(() => {
                                                            handleSendButtonClick();
                                                            setIsSending(false); // 타임홀드가 끝난 후 상태를 원래대로 복구
                                                        }, 1000);
                                                    };
                                                }
                                            }}
                                        />
                                        <IconButton
                                            borderRadius="13px"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleSendButtonClick();
                                            }
                                            }
                                            icon={<IoIosSend />}
                                            borderColor="#c84557"
                                            borderWidth='2px'
                                            color="#c84557"
                                            height="40px"
                                            bg='transparent'
                                            _hover={{ bg: '#c84557', color: 'white' }}
                                        />
                                    </HStack>
                                </>
                            )}
                        </Flex>
                    </Container>

                    {isNode0CurrentResponseBeing && currentNodeIndex === 0 && (
                        <Container
                            borderRadius="16px"
                            p='5'
                            h='90vh'
                            bg={colors.ContainerCenterBg}
                            minW='container.md'
                            overflow='auto'
                            borderWidth="2px"
                            borderColor="#C0D1D4"
                            mt="2"
                        >

                            {documentSummaryResponse.report_title ? (
                                <Card borderRadius='16'>
                                    <CardBody>
                                        <Flex>
                                            <Center
                                                fontSize='2xl'
                                                fontWeight='bold'
                                                maxW='100%'
                                                overflow='hidden'
                                                whiteSpace='nowrap'
                                                textOverflow='ellipsis'
                                                display="block"
                                                borderRadius="12px"
                                                width="100%"
                                                mr="2"
                                            >
                                                {documentSummaryResponse.report_title}
                                            </Center>
                                            <Spacer />
                                            <HStack>
                                                <IconButton
                                                    icon={<IoMdDownload />}
                                                    borderRadius='15px'
                                                    bg={colors.NavigationBarBg}
                                                    onClick={handlePostMultitaskDownloadReport}
                                                />
                                            </HStack>
                                        </Flex>
                                    </CardBody>
                                </Card>
                            ) : (
                                <Box position="relative">
                                    <Skeleton
                                        height="5em"
                                        width="100%"
                                        borderRadius="12px"
                                        startColor="gray.200"
                                        endColor="gray.300"
                                    />
                                    {isQueryLoading && (
                                        <Text
                                            position="absolute"
                                            top="50%"
                                            left="50%"
                                            transform="translate(-50%, -50%)"
                                            fontSize="lg"
                                            fontWeight="bold"
                                            textAlign="center"
                                            style={{
                                                background: "linear-gradient(90deg, #1E90FF, #000000, #1E90FF)",
                                                backgroundSize: "200% 200%",
                                                WebkitBackgroundClip: "text",
                                                WebkitTextFillColor: "transparent",
                                                animation: "gradientAnimation 3s ease infinite",
                                            }}
                                        >
                                            문서 생성 중입니다. 잠시만 기다려주세요.
                                        </Text>
                                    )}
                                </Box>
                            )}

                            <Box mt='4'>
                                {documentSummaryResponse.markdown_content ? (
                                    <ReactMarkdown
                                        components={documentSummaryMDTheme}
                                        remarkPlugins={[remarkGfm]}
                                    // skipHtml
                                    >
                                        {documentSummaryResponse.markdown_content}
                                    </ReactMarkdown>
                                ) : (
                                    <Box position="relative">
                                        <Skeleton
                                            borderRadius="15px"
                                            height="740px" // 원하는 높이로 조정
                                            width="100%"   // 전체 폭을 차지하게 설정
                                            startColor="gray.200"
                                            endColor="gray.300"
                                        />
                                        {isQueryLoading && (
                                            <Box
                                                position="absolute"
                                                top="50%"
                                                left="50%"
                                                transform="translate(-50%, -50%)"
                                                textAlign="center"
                                                borderRadius="8px"
                                            >
                                                <Text
                                                    fontSize="lg"
                                                    fontWeight="bold"
                                                    style={{
                                                        background: "linear-gradient(90deg, #1E90FF, #000000, #1E90FF)",
                                                        backgroundSize: "200% 200%",
                                                        WebkitBackgroundClip: "text",
                                                        WebkitTextFillColor: "transparent",
                                                        animation: "gradientAnimation 3s ease infinite",
                                                    }}
                                                >
                                                    문서 생성 중입니다. 잠시만 기다려주세요.
                                                </Text>
                                                <Text
                                                    fontSize="md"
                                                    fontWeight="bold"
                                                    style={{
                                                        background: "linear-gradient(90deg, #1E90FF, #000000, #1E90FF)",
                                                        backgroundSize: "200% 200%",
                                                        WebkitBackgroundClip: "text",
                                                        WebkitTextFillColor: "transparent",
                                                        animation: "gradientAnimation 3s ease infinite",
                                                    }}
                                                >
                                                    5분 이상 소요될 수 있습니다.
                                                </Text>
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Container>
                    )}
                </HStack>
            </Flex>
            <Input
                type="file"
                ref={fileInputRefs}
                hidden
                accept=".pdf, .hwp, .txt, .csv"
                onChange={handleFileChange}
                multiple
            />
        </Container>
    )
}

export default PlaygroundPage;